import { sessionConstants, recordConstants } from '../constants'
import { recordServices } from '../services'
import { alertActions } from './index'

export const recordsActions = {
  create,
  get,
  update,
  destroy,
  refresh,
}

export function request() {
  return { type: recordConstants.RECORD_REQUEST }
}

export function success(records) {
  return { type: recordConstants.RECORD_SUCCESS, records }
}

export function failure(errors) {
  return { type: recordConstants.RECORD_FAILURE, errors }
}

function create(params) {
  return dispatch => {
    recordServices.create(params).then(
      response => {
        if ('token' in response.data) {
          const token = response.data.token
          localStorage.setItem('token', token)
          dispatch(authSuccess(token))
        }
        dispatch(recordsActions.get())
        dispatch(alertActions.success("Child was added successfully, it may take a moment before they appear on your dashboard"))
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors
          if (errors) {
            errors.forEach(e => {
              dispatch(alertActions.error(e))
            })
          }
        }
      }
    )
  }
}

//need this to update token values
export function authSuccess(token) {
  return { type: sessionConstants.SESSION_UPDATE_SUCCESS, token }
}

function update(params) {
  return dispatch => {
    recordServices.update(params).then(
      response => {
        dispatch(recordsActions.get())
        dispatch(alertActions.success("Child updated"))
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors
          if (errors) {
            errors.forEach(e => {
              dispatch(alertActions.error(e))
            })
          }
        }
      }
    )
  }
}

function get() {
  return dispatch => {
    dispatch(request())
    recordServices.get().then(
      response => {
        const records = response.data.data
        if (records) {
          localStorage.setItem('records', JSON.stringify(records))
          dispatch(success(records))
        }
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors
          if (errors) {
            dispatch(failure(errors))
            errors.forEach(e => {
              dispatch(alertActions.error(e))
            })
          }
        }
      }
    )
  }
}

function destroy(params) {
  return dispatch => {
    recordServices.destroy(params).then(
      response => {
        dispatch(alertActions.success("Child deleted"))
        dispatch(recordsActions.get())
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors
          if (errors) {
            errors.forEach(e => {
              dispatch(alertActions.error(e))
            })
          }
        }
      }
    )
  }
}

function refresh(records) {
  return dispatch => {
    localStorage.setItem('records', JSON.stringify(records))
    dispatch({ type: recordConstants.RECORD_REFRESH, records })
  }
}
