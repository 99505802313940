import React from 'react'
import header from '../../images/Consent/Calgary/logo.png'

export class AlbertaBalletAssent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      child_name: '',
      person_receiving_assent_name: '',
      take_part_in_study: ''
    }
  }

  validateComplete = () => {
    const {
      child_name, take_part_in_study, person_receiving_assent_name
    } = this.state

    let data = this.state
    data.formValid = true

    if (child_name && person_receiving_assent_name && take_part_in_study === 'true') {
      data.formValid = true
    } else {
      data.formValid = false
    }

    this.props.handleChange(data)
  }

  handleChange = event => {
    const {name, value} = event.target
    if (['child_name', 'take_part_in_study', 'person_receiving_assent_name'].includes(name)) {
      this.setState(
        {
          [name]: value,
        },
        this.validateComplete
      )
    }
  }

  render() {
    const {child_name, person_receiving_assent_name} = this.state

    return (
      <div>
        <img
          src={header}
          alt="header"
          style={{
            width: '150px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
        <br/>
        <br/>
        <br/>
        <div style={{textAlign: 'center', width: '100%'}}>
          <h5>UNIVERSITY OF CALGARY</h5>
          <h5>GENERAL ASSENT TO PARICIPATE IN RESEARCH</h5>
        </div>
        <br/>
        <p>
          <b>Title: </b>
          <br/>Burden of health problems in dancers
        </p>
        <p>
          <b>Sponsor: </b>
          <br/>Sport Injury Prevention Research Centre, University of Calgary
        </p>
        <p>
          <b>Investigators: </b>
          <br/>
          <div style={{display: 'flex'}}>
            <div style={{width: '200px'}}>Principal Investigator:</div>
            <div style={{width: '200px'}}>Dr. Sarah Kenny<br/></div>
          </div>
          <div style={{display: 'flex'}}>
            <div style={{width: '200px'}}>Co-Investigators:</div>
            <div style={{width: '400px'}}>Dr. Meghan Critchley & Dr. Carolyn Emery<br/></div>
          </div>
        </p>
        <p>
          <b>Contact Information: </b>
          <br/>
          <div style={{display: 'flex'}}>
            <div style={{width: '200px'}}>Email:</div>
            <div style={{width: '200px'}}><i>danceinjury@ucalgary.ca</i><br/></div>
          </div>
        </p>
        <p>
          <b>Introduction:</b>
          <br/>
          A research study is a way to find out new information about something. People don’t need to participate in a
          research study if they don’t want to participate. You can talk this over with your parents or guardians before
          you decide whether or not to participate. We will also ask your parents or guardians to give their permission
          for you to take part in this study. But even if your parents or guardians say “yes” you can still decide not
          to do this.
        </p>
        <p>
          <b>Why is this study being done?</b>
          <br/>
          A dance injury or illness can keep you from dancing and being active, which can change how healthy you are.
          Research is important to help understand how to keep these dance injuries and illnesses from happening. It
          helps us understand how many injuries and illnesses there are and what increases the chances of getting hurt
          or sick. This research will also help us come up with ways to stop injuries and illnesses from happening in
          the future. You are being asked to take part in this research study because we are trying to learn more about
          health problems that dancers are getting. We are asking you to be in the study because you are a dancer in
          Calgary and area. About 200 people will be in this study.
        </p>
        <p>
          <b>What will happen if I take part in this study?</b>
          <br/>
          We want to tell you about some things that will happen if you are in this study.
        </p>
        <p>
          <b>Before dance training starts:</b>
          <br/>
          You will fill out some more forms online at home that include a medical questionnaire, a dance history form,
          and a form that tells us how well you manage being a dancer. After completing the forms, you will participate
          in physical assessments that will measure how tall you are, how much you weigh, your foot position, how much
          range of motion is in your ankles and hips, the strength of your legs, how well you control your pelvis, and
          balance. A certified physiotherapist and trained research assistant will conduct all the assessments. It
          should take approximately one hour to complete all tests. You will be excused from class to participate. All
          assessments will take place at your dance studio in front of others and be repeated at the end of the dance
          season.
        </p>
        <p>
          <b>During the school year</b>
          <br/>
          After testing, you will keep track of how many hours you dance each week (including classes, rehearsals, and
          performances). You will keep track if you get injured or have an illness at any time during the school year by
          filling out a weekly questionnaire online. An injury means any physical or psychological complaint resulting
          from class, rehearsal, or performance regardless of its consequences (e.g., having to miss class or see a
          medical practitioner). A physiotherapist will support you if you get injured, providing recommendations for
          any treatment you may need. The cost of medical care will be at your parent/guardian’s cost or health
          coverage.
        </p>
        <p>
          <b>Are there any potential risks or discomforts that I can expect from this study?</b>
          <br/>
          We will do everything we can to ensure that you do not experience any discomfort during the physical
          assessments. It is possible that discomfort could arise during the tests, but this is unlikely, because you
          will be moving within your normal ranges of movement and muscle strength. If you experience discomfort,
          testing will stop immediately.
        </p>
        <p>
          <b>Will the study help me?</b>
          <br/>
          If you agree to be in this study, there may or may not be a direct advantage. You may have lower chance of
          injury or illness during the study but there is no guarantee that this research will help you. If you have a
          dance injury or illness during the study, the physiotherapist will assess you and give you advice about any
          treatment they think would help you.
        </p>
        <p>
          <b>Will the study help others?</b>
          <br/>
          This study might find out things that will help other young dancers not get injured or sick as much.
        </p>
        <p>
          <b>Who will see the information collected about me?</b>
          <br/>
          The information collected about you during this study will be kept safely locked up. Nobody will read it
          except the people doing the research. The study information about you will not be given to your parents,
          guardians, or instructors. The researchers won’t tell your friends or anyone else that you are in this study
          or share any information about you.
          <br/>
          <br/>
          If any mental health concerns requiring care are uncovered, this information may be shared with your
          parent/guardian. You and your parent/guardian may be guided by a member of the research team to follow up with
          your family physician and provided a list of resources (e.g., local dance psychologists).
        </p>
        <p>
          <b>Do I have to be in the study?</b>
          <br/>
          You don’t have to be in the study. It is up to you. No one will be upset if you don’t want to do this study.
          You can say yes, or you can say no. You can also take more time to think about being in the study. If you want
          to stop, then all you have to do is tell us you want to stop. No one will be mad at you if you don’t want to
          be in the study, or if you say yes now then want to stop later.
        </p>
        <p>
          <b>What do I get for being in the study?</b>
          <br/>
          You do not get any money or gifts for being in this research study.
        </p>
        <p>
          <b>Do I have to pay for anything?</b>
          <br/>
          You will not have to pay for anything. There are no costs associated with this study as participation will
          take place at your dance studio.
        </p>
        <p>
          <b>Who can I contact if I have questions about this study?</b>
          <br/>
          You can ask any questions that you may have about the study. If you have a question later that you don’t think
          of now, either you or your parents can email Dr. Sarah Kenny at danceinjury@ucalgary.ca or call at
          402-220-2770. You can also take more time to think about being in the study and talk some more with your
          parents about being in the study.
        </p>
        <hr/>
        <p>
          <b>Would you like to take part in this study?</b>
          <br/>
          If you decide to be in the study, then please write your name below. You can change your mind and stop being
          part of the study at any time. All you have to do is tell us. It’s okay. The researchers and your parents
          won’t be upset with you.
        </p>
        <div className="form-group row">

          <div className="col-lg-4 mb-2">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="take_part_in_study"
                  id="take_part_in_study_yes"
                  value={true}
                  onClick={this.handleChange}
                />
                Yes, I want to be in this study
              </label>
            </div>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="take_part_in_study"
                  id="take_part_in_study_no"
                  value={false}
                  onClick={this.handleChange}
                />
                No, I dont want to do this
              </label>
            </div>
          </div>
        </div>
        <br/>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Name of Participant:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="child_name"
              name="child_name"
              value={child_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Name of Person who received assent:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="person_receiving_assent_name"
              name="person_receiving_assent_name"
              value={person_receiving_assent_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <hr/>
      </div>
    )
  }
}
