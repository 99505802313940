import React from 'react'
import {push} from 'connected-react-router'
import {connect} from 'react-redux'

import {store} from '../../helpers'

export class About extends React.Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(event) {
    event.preventDefault()
    const {dispatch} = store

    dispatch(push('/signup'))
  }

  render() {
    const {isAuthenticated} = this.props

    return (
      <div>
        <div className="container mb-5">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-9 col-sm-12">
                  <h2 className="heading mb-5">
                    an
                    <span className="text-primary">
                      {" introduction"}
                    </span>
                  </h2>
                </div>
                <div className="col-lg-3 col-sm-12 mb-5">
                  {!isAuthenticated && (
                    <div className="text-center">
                      <h5 className="mb-2">
                        SIGN UP NOW!
                      </h5>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={this.handleClick}
                      >
                        Click here to sign up
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <h5 className="mb-4">
                Dance Injuries Study
              </h5>
              <p className="text-justify mb-5">
                Thank you for your important contribution through participation in this dance injury study. A lot of
                children and youth take dance classes and it is common to get injured in these dance classes. A dance
                injury will keep you from dancing and being active, which can change how healthy you are. Research is
                important to help understand how to keep these injuries in dance from happening. It helps us understand
                how many are dancing, how many injuries there are, what makes the chances of getting injured higher and
                what people can do to lower the chances of getting hurt right now. This will help researchers come up
                with ways to stop injuries from happening in the future.
                <br/>
                <br/>
              </p>
              <h5 className="mb-4">
                ETHICS APPROVAL
              </h5>

              <p className="mb-5 text-justify">
                This study has been approved by the Research Ethics Board at the University of Calgary (REB14-0897/ REBB 22-1365) and the Wake Forest University Institutional Review Board (IRB-00089998).
              </p>
              <div className="col-lg-9 col-sm-12 pl-0">
                <div className="well pl-3 pr-3">
                  <h5 className={'mb-3 mt-3 text-primary'}>
                    How to sign up:
                  </h5>
                  <ol>
                    <li className="mb-1">
                      Have your parents go to dance.shredinjuries.ca
                    </li>
                    <li className="mb-1">
                      Have your parents create a parent account
                    </li>
                    <ul>
                      <li>
                        Enter their email and create a password
                      </li>
                      <li>
                        Read and sign the parental consent form
                      </li>
                    </ul>
                    <li className="mb-1">
                      Your parent will receive an email from no-reply@shredinjuries.ca asking to confirm their account
                    </li>
                    <li className="mb-1">
                      Your parent must then login to their account at dance.shredinjuries.ca
                    </li>
                    <li className="mb-1">
                      Parents click on the “Add Child” tab
                    </li>
                    <ul>
                      <li>
                        Enter child’s birthdate, name, email, phone, and school/dance studio
                      </li>
                      <li>
                        Click “Add Child”
                      </li>
                      <li>
                        Complete the “Additional Info” Survey
                      </li>
                    </ul>
                    <li className="mb-1">
                      You (the participant) will then receive an email from no-reply@shredinjuries.ca
                    </li>
                    <ul>
                      <li>
                        Click the link in the email
                      </li>
                      <li>
                        Create your password
                      </li>
                      <li>
                        Click the red “Assent” text to read and sign the assent form and click “Agree” then click “Activate”
                      </li>
                    </ul>
                    <li className="mb-1">
                      Sign into your account
                    </li>
                    <ul>
                      <li>
                        Please complete all baseline questionnaires on your account
                      </li>
                      <li>
                        Remember to login weekly to complete online injury surveys
                      </li>
                    </ul>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {isAuthenticated} = state.session

  return {
    isAuthenticated,
  }
}

export default connect(mapStateToProps)(About)
