import React, {createRef} from 'react'

import ReactTooltip from 'react-tooltip'
import {HelpCircle} from 'react-feather'
import PhoneInput from 'react-phone-number-input'
import {ClipLoader} from 'react-spinners'

import {LoginModal} from '../../components/Modals'
import ConfirmationSentModal from '../../components/Modals/ConfirmationSentModal'
import ConsentFormModal from '../../components/Modals/ConsentFormModal'
import {store} from '../../helpers'
import {cityServices, userServices} from '../../services'
import {alertActions} from '../../actions'
import {FormErrors} from '../../components/FormErrors'

let cancel = {exec: null}

class Signup extends React.Component {
    constructor(props) {
        super(props)

        this.signupButtonRef = createRef()

        this.state = {
            email: '',
            password: '',
            password_confirmation: '',
            phone_number: '',
            signature: '',
            terms: false,
            city: '',
            consent_content: {},
            formErrors: {
                email: '',
                password: '',
                password_confirmation: '',
                terms: '',
            },
            emailValid: false,
            passwordValid: false,
            passwordConfirmationValid: false,
            cityValid: false,
            phoneNumberValid: false,
            formValid: false,
            showConsentModal: false,
            showSentModal: false,
            showLoginModal: false,
            cities: [],
            loading: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.validateCity = this.validateCity.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.toggleConsentModal = this.toggleConsentModal.bind(this)
        this.handleConsentModalAccept = this.handleConsentModalAccept.bind(this)
        this.toggleSentModal = this.toggleSentModal.bind(this)
        this.toggleLoginModal = this.toggleLoginModal.bind(this)
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this)
    }

    componentDidMount() {
        const {dispatch} = store

        cityServices.get(cancel).then(
            response => {
                const data = response.data

                this.setState({
                    cities: data,
                })
            },
            error => {
                if (error.response) {
                    const errors = error.response.data.errors
                    if (errors) {
                        errors.forEach(e => {
                            dispatch(alertActions.error(e))
                        })
                    }
                }
            }
        )
    }

    componentWillUnmount() {
        if (cancel.exec) cancel.exec()
    }

    handleChange(event, type = '') {
        let name = ''
        let value = ''
        if (type === 'phone_number') {
            name = 'phone_number'
            value = event
        } else {
            name = event.target.name
            value = event.target.value
        }

        this.setState({[name]: value}, () => {
            this.validateField(name, value)
        })
    }

    handleSubmit(event) {
        event.preventDefault()
        const {
            formValid,
            email,
            password,
            password_confirmation,
            city,
            terms,
            signature,
            phone_number,
            consent_content,
        } = this.state
        const {dispatch} = store

        if (formValid) {
            this.setState({loading: true})

            const params = {
                email,
                password,
                password_confirmation,
                terms,
                signature,
                phone_number,
                city,
                consent_content,
            }

            userServices.create(params).then(
                response => {
                    this.setState({
                        loading: false,
                        email: '',
                        password: '',
                        password_confirmation: '',
                        phone_number: '',
                        signature: '',
                        terms: false,
                        consent_content: {},
                        formErrors: {
                            email: '',
                            password: '',
                            password_confirmation: '',
                            terms: '',
                        },
                        emailValid: false,
                        passwordValid: false,
                        passwordConfirmationValid: false,
                        phoneNumberValid: false,
                        formValid: false,
                        showSentModal: true,
                    })
                },
                error => {
                    this.setState({loading: false})
                    if (error.response) {
                        const errors = error.response.data.errors
                        if (errors) {
                            errors.forEach(e => {
                                dispatch(alertActions.error(e))
                            })
                        }
                    }
                }
            )
        }
    }

    populateCities() {
        const {cities} = this.state

        let items = []
        if (cities.length) {
            for (let i = 0; i < cities.length; i++)
                items.push(<option key={i}>{cities[i]} </option>)
        }
        return items
    }

    validateCity(e) {
        if (e.target.value === 'disabled') {
            this.setState(
                {
                    cityValid: false,
                    city: '',
                },
                this.validateForm
            )
        } else {
            this.setState(
                {
                    cityValid: true,
                    city: e.target.value,
                    terms: false,
                },
                this.validateForm
            )
        }
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors
        let {
            emailValid,
            passwordValid,
            passwordConfirmationValid,
            phoneNumberValid,
        } = this.state
        const {password, password_confirmation} = this.state

        switch (fieldName) {
            case 'email':
                if (value !== '') {
                    emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
                    fieldValidationErrors.email = emailValid ? '' : "Please enter a valid email address."
                } else fieldValidationErrors.email = ''
                break
            case 'password':
                if (value !== '') {
                    passwordValid = value.length >= 6
                    fieldValidationErrors.password = passwordValid ? '' : "Password must be 6 characters long."
                    if (passwordValid) {
                        passwordConfirmationValid =
                            value === password_confirmation &&
                            password_confirmation.length > 0
                        fieldValidationErrors.password_confirmation = passwordConfirmationValid ? '' : "Passwords do not match."
                    }
                } else {
                    passwordValid = false
                    passwordConfirmationValid = false
                    fieldValidationErrors.password = ''
                    fieldValidationErrors.password_confirmation = ''
                }
                break
            case 'password_confirmation':
                if (value !== '') {
                    passwordConfirmationValid = value === password
                    fieldValidationErrors.password_confirmation = passwordConfirmationValid ? '' : "Passwords do not match."
                } else passwordConfirmationValid = false
                if (passwordValid)
                    fieldValidationErrors.password_confirmation = passwordConfirmationValid ? '' : "Passwords do not match."
                else fieldValidationErrors.password_confirmation = ''
                break
            case 'phone_number':
                phoneNumberValid = value !== ''
                break
            default:
                break
        }

        this.setState(
            {
                formErrors: fieldValidationErrors,
                emailValid: emailValid,
                passwordValid: passwordValid,
                phoneNumberValid: phoneNumberValid,
                passwordConfirmationValid: passwordConfirmationValid,
            },
            this.validateForm
        )
    }

    validateForm() {
        const {
            emailValid,
            passwordValid,
            passwordConfirmationValid,
            phoneNumberValid,
            cityValid,
            terms,
        } = this.state

        this.setState({
            formValid:
                emailValid &&
                passwordValid &&
                passwordConfirmationValid &&
                phoneNumberValid &&
                cityValid &&
                terms,
        })
    }

    toggleLoginModal() {
        const {showLoginModal} = this.state

        this.setState({
            showLoginModal: !showLoginModal,
        })
    }

    handleLoginSubmit() {
        const {isAuthenticated} = this.props

        if (isAuthenticated) {
            this.setState({
                showLoginModal: false,
            })
        }
    }

    toggleConsentModal() {
        const {showConsentModal} = this.state

        this.setState({
            showConsentModal: !showConsentModal,
        })
    }

    handleConsentModalAccept(signature, consent_content) {
        let fieldValidationErrors = this.state.formErrors

        if (signature) {
            this.signupButtonRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            })
            this.setState(
                {
                    signature: signature,
                    showConsentModal: false,
                    terms: true,
                    consent_content: consent_content,
                },
                this.validateForm
            )
        } else {
            fieldValidationErrors.terms = 'Please read and sign the consent form'

            this.setState(
                {
                    showConsentModal: false,
                    terms: false,
                    formErrors: fieldValidationErrors,
                },
                this.validateForm
            )
        }
    }

    toggleSentModal() {
        const {showSentModal} = this.state

        this.setState({
            showSentModal: !showSentModal,
        })
    }

    render() {
        const {
            showLoginModal,
            showConsentModal,
            showSentModal,
            formErrors,
            email,
            password,
            password_confirmation,
            phone_number,
            city,
            loading,
        } = this.state
        const {isAuthenticated} = this.props

        return (
            <div>
                <LoginModal
                    showModal={showLoginModal}
                    isAuthenticated={isAuthenticated}
                    onClose={this.toggleLoginModal}
                    onSubmit={this.handleLoginSubmit}
                />
                <ConsentFormModal
                    showModal={showConsentModal}
                    signatureAllowed={true}
                    city={city}
                    role={'parent'}
                    onClose={this.toggleConsentModal}
                    onAccept={this.handleConsentModalAccept}
                />
                <ConfirmationSentModal
                    showModal={showSentModal}
                    onClose={this.toggleSentModal}
                />
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-9">
                            <h2 className="heading">
                                PARENT{" "}
                                <span className="text-primary">
                  SIGN UP
                </span>
                                <ReactTooltip
                                    id="tooltip"
                                    place="right"
                                    effect="solid"
                                    type="light"
                                    multiline
                                    className="description-tooltip"
                                />
                                <HelpCircle
                                    size={22}
                                    className="ml-2 accent-color-1"
                                    style={{cursor: 'pointer'}}
                                    data-tip="Once you have signed up you will click the 'Add Child' tab to create your child's account"
                                    data-for={'tooltip'}
                                />
                            </h2>
                            <div className="mt-2 mb-2">
                                <FormErrors formErrors={formErrors}/>
                            </div>
                            <form name="form" onSubmit={this.handleSubmit}>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">
                                        Parent Email
                                    </label>
                                    <div className="col-lg-6 mb-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            value={email}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">
                                        Phone Number
                                    </label>
                                    <div className="col-lg-6 mb-2">
                                        <PhoneInput
                                            className='form-control'
                                            countries={['CA', 'US', 'AU']}
                                            name='phone_number'
                                            defaultCountry='CA'
                                            value={phone_number}
                                            onChange={value => this.handleChange(value, 'phone_number')}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">
                                        Password
                                    </label>
                                    <div className="col-lg-6 mb-2">
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            value={password}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">
                                        Confirm password
                                    </label>
                                    <div className="col-lg-6 mb-2">
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="password_confirmation"
                                            value={password_confirmation}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">
                                        <ReactTooltip
                                            id="tooltip1"
                                            place="left"
                                            effect="solid"
                                            type="light"
                                            className="description-tooltip"
                                            multiline
                                        />
                                        <HelpCircle
                                            size={22}
                                            className="ml-2 accent-color-1"
                                            style={{cursor: 'pointer', position: 'absolute', left: '25px'}}
                                            data-tip="Please select the University that contacted you about participation in this study"
                                            data-for={'tooltip1'}
                                        />
                                        University
                                    </label>
                                    <div className="col-lg-6 mb-2">
                                        <select
                                            className="form-control "
                                            name="city_name"
                                            onChange={this.validateCity}
                                        >
                                            <option value="disabled">
                                                Select University...
                                            </option>
                                            {this.populateCities()}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-check mt-5">
                  <span className="lead">
                    <span style={{color: '#D32F2F'}}>*</span>
                    You must read and sign the{" "}
                      <span className="btn-custom" onClick={this.toggleConsentModal}>consent form</span>
                  </span>
                                </div>
                                <button
                                    ref={this.signupButtonRef}
                                    type="submit"
                                    name="submit"
                                    className="btn btn-primary mt-5 mb-2"
                                    disabled={!this.state.formValid || loading}
                                >
                                    Signup{' '}
                                    <ClipLoader loading={loading} size={15} color="#fff"/>
                                </button>
                                <div>
                  <span
                      className="btn-custom"
                      onClick={this.toggleLoginModal}
                      style={{fontSize: '14px'}}
                  >
                    Already have an account?
                  </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Signup
