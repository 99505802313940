import React from 'react'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { Spinner } from '../../components/Spinner'
import { HelpCircle } from 'react-feather'

import { Parent } from '../../components/Profile/Parent/Parent'
import { recordsActions } from '../../actions'
import { store } from '../../helpers'
import Child from '../../components/Profile/Child/Child'

export class Dashboard extends React.Component {
  componentDidMount() {
    const { dispatch } = store
    if (!this.props.records) dispatch(recordsActions.get())
  }

  render() {
    const {records, recordLoading, recordError, role, location, firstName,} = this.props
    let tip = ''
    if (role === 'parent') tip = "Welcome to the parent home page. Here you will find the surveys and information for each of your children. Surveys are available and answered on a per child basis"
    else if (role === 'child') tip = "Welcome to the dancer home page. Here you will find your various surveys."

    return (
      <div className="container">
        {recordLoading ? (
          <div className="text-center">
            <Spinner loading={recordLoading} />
          </div>
        ) : recordError ? (
          <div>
            <p>
              Something went wrong loading your records, try refreshing the page to try again.
            </p>
          </div>
        ) : (
          <div>
            {tip && (
              <ReactTooltip
                id="tooltip"
                place="right"
                effect="solid"
                type="light"
                multiline
                className="description-tooltip"
              />
            )}
            {records && records.length > 0 && (
              <div>
                <h1 className="d-inline-block mb-5">
                  Hello{' '}
                  <span className="h1">{firstName}</span>
                </h1>
                <HelpCircle
                  size={22}
                  className="ml-2 accent-color-1"
                  style={{ cursor: 'pointer' }}
                  data-tip={tip}
                  data-for={'tooltip'}
                />
              </div>
            )}
            {role === 'parent' ? (
              <Parent
                {...this.props}
                records={records}
                role={role}
                location={location}
              />
            ) : (
              <Child
                record={records ? records[0] : null}
                role={role}
                location={location}
              />
            )}
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { role, firstName } = state.session
  const recordLoading = state.record.loading
  const recordError = state.record.error
  const records = state.record.records

  return {
    recordLoading,
    recordError,
    records,
    role,
    firstName,
  }
}

export default connect(mapStateToProps)(Dashboard)
