import React from 'react'
import DatePicker from 'react-datepicker'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'

import { newInjuryServices } from '../../../services'
import { store } from '../../../helpers/index'
import { alertActions, sportActions } from '../../../actions'
import './Child.css'
import { Section } from '../SectionHeading/Section'

class NewConcussionForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      injuryDate: '',
      validated: false,
      symptomValue: '',
      preTreatedValue: '',
      sportValue: '',
    }

    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.checkValid = this.checkValid.bind(this)
    this.handleSymptomChange = this.handleSymptomChange.bind(this)
    this.handlePreTreatedChange = this.handlePreTreatedChange.bind(this)
    this.handleSportChange = this.handleSportChange.bind(this)
  }

  componentDidMount() {
    const { dispatch } = store

    if (!this.props.sports) dispatch(sportActions.get())
  }

  handleDateChange(date) {
    if (date) {
      this.setState(
        {
          injuryDate: date,
        },
        this.checkValid
      )
    }
  }

  handleDateChangeRaw = e => {
    e.preventDefault()
  }

  handleSportChange(e) {
    let x = e.currentTarget.value
    if (e.currentTarget.value === 'select..') {
      x = ''
    } else {
      x = e.currentTarget.value
    }
    this.setState(
      {
        sportValue: x,
      },
      this.checkValid
    )
  }

  handleSymptomChange(e) {
    this.setState(
      {
        symptomValue: e.currentTarget.value,
      },
      this.checkValid
    )
  }

  handlePreTreatedChange(e) {
    this.setState(
      {
        preTreatedValue: e.currentTarget.value,
      },
      this.checkValid
    )
  }

  handleSubmit() {
    const { dispatch } = store
    const { injuryType } = this.props
    const {
      validated,
      injuryDate,
      symptomValue,
      preTreatedValue,
      sportValue,
    } = this.state

    if (validated) {
      newInjuryServices
        .create({
          injury_date: injuryDate,
          symptom: symptomValue,
          pre_treated: preTreatedValue,
          sport: sportValue,
          injury_type: injuryType === 'newConcussion' ? 'concussion' : 'injury',
        })
        .then(
          response => {
            if (injuryType === 'newConcussion') {
              dispatch(alertActions.success("New Concussion Submitted"))
              this.props.handleInjuryChange()
              this.props.handleConcussionSubmit()
            } else
              dispatch(alertActions.success("New Non-Concussion Injury Submitted"))

            this.setState({
              injuryDate: '',
              validated: false,
              sportValue: '',
            })
            dispatch(
              push({
                pathname: '/profile/dashboard',
                search: '?childTab=0&contentTab=baseline',
              })
            )
          },
          error => {
            if (error.response) {
              const errors = error.response.data.errors
              if (errors) {
                errors.forEach(e => {
                  dispatch(alertActions.error(e))
                })
              }
            }
          }
        )
    }
  }

  checkValid() {
    const { injuryDate, preTreatedValue, symptomValue, sportValue } = this.state

    if (
      injuryDate &&
      preTreatedValue &&
      symptomValue &&
      sportValue !== 'Select sport...'
    ) {
      this.setState({
        validated: true,
      })
    } else {
      this.setState({
        validated: false,
      })
    }
  }

  render() {
    const { injuryType, sports, role } = this.props
    const { validated, injuryDate, sportValue } = this.state

    const text =
      injuryType === 'newConcussion' ? 'concussion' : 'injury'

    return (
      <div>
        <Section heading={injuryType === 'newConcussion' ? 'New Concussion' : 'New Non-Concussion Injury'} role={role}/>
        <div style={{minHeight: '70vh', maxWidth: '700px', paddingLeft: '15px',}}>
          <div>
            <div className="form-group row">
              <label className="col-lg-6 col-form-label">
                {"Date of " + text}
              </label>
              <div className="col-lg-4 mb-2">
                <DatePicker
                  id="injuryDate"
                  className="form-control"
                  name="injuryDate"
                  selected={injuryDate}
                  placeholderText="yy/mm/dd"
                  maxDate={new Date()}
                  peekNextMonth
                  dropdownMode="select"
                  onChange={this.handleDateChange}
                  onChangeRaw={this.handleDateChangeRaw}
                  autoComplete="off"
                  dateFormat="yy/MM/dd"
                ></DatePicker>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-6 col-form-label">
                Sport
              </label>
              <div className="col-lg-4 mb-2">
                <select
                  className="form-control"
                  onChange={this.handleSportChange}
                  value={sportValue}
                >
                  <option>
                    Select sport...
                  </option>
                  {sports &&
                    sports.map((sport, index) => {
                      return (
                        <option value={sport.id} key={index}>
                          {sport.name}
                        </option>
                      )
                    })}
                </select>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-6 col-form-label">
                Were you having symptoms related to a previous concussion in the day(s) just prior to this new
                concussion?
              </label>

              <div className="col-lg-4 mb-2">
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="symptom"
                      id="symptom_yes"
                      value="1"
                      onClick={this.handleSymptomChange}
                    />
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="symptom"
                      id="symptom_no"
                      value="0"
                      onClick={this.handleSymptomChange}
                    />
                    No
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-6 col-form-label">
                {"Were you being treated for a previous " + text + " in the day(s) just prior to this new " + text + '?'}
              </label>

              <div className="col-lg-4 mb-2">
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="pre_treated"
                      id="pre_treated_yes"
                      value="1"
                      onClick={this.handlePreTreatedChange}
                    />
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="pre_treated"
                      id="pre_treated_no"
                      value="0"
                      onClick={this.handlePreTreatedChange}
                    />
                    No
                  </label>
                </div>
              </div>
            </div>

            <br />
            <br />
            <button
              style={{ float: 'left' }}
              type="button"
              className={validated ? 'btn btn-primary' : 'btn btn-disabled'}
              onClick={this.handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { sports } = state.sport

  return {
    sports
  }
}

export default connect(mapStateToProps)(NewConcussionForm)
