import React from 'react'
import ReactModal from 'react-modal'
import Modal from 'react-modal'
import { XCircle } from 'react-feather'
import { Edit2 } from 'react-feather'

import './Modal.css'
import { store } from '../../helpers'
import { recordsActions, alertActions } from '../../actions'
import { FormErrors } from '../FormErrors'
import { activateServices } from '../../services'
import ChildInfo from '../Profile/Parent/ChildInfo'
import ConfirmationModal from './ConfirmationModal'

class EditChildModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: props.child.email ? props.child.email : '',
      first_name: props.child.first_name ? props.child.first_name : '',
      formErrors: { email: '' },
      emailValid: true,
      showNameEdit: false,
      showEmailEdit: props.child.state === 'pending',
      deleteConfirmModal: false,
      optoutConfirmModal: false,
    }

    this.onOptOutConfirm = this.onOptOutConfirm.bind(this)
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleEmailSubmit = this.handleEmailSubmit.bind(this)
    this.handleNameSubmit = this.handleNameSubmit.bind(this)
    this.toggleNameEdit = this.toggleNameEdit.bind(this)
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this)
    this.toggleOptoutModal = this.toggleOptoutModal.bind(this)
  }

  componentDidMount() {
    Modal.setAppElement('body')
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      email: nextProps.child.email ? nextProps.child.email : '',
      first_name: nextProps.child.first_name ? nextProps.child.first_name : '',
      showEmailEdit: nextProps.child.state === 'pending',
    })
  }

  componentDidUpdate() {
    const { showNameEdit } = this.state

    if (showNameEdit) if (this.nameInput) this.nameInput.focus()
  }

  toggleDeleteModal() {
    const { deleteConfirmModal } = this.state
    this.setState({
      deleteConfirmModal: !deleteConfirmModal,
    })
  }

  toggleOptoutModal() {
    const { optoutConfirmModal } = this.state
    this.setState({
      optoutConfirmModal: !optoutConfirmModal,
    })
  }

  handleChange(event) {
    const { name, value } = event.target
    this.setState({ [name]: value }, () => {
      this.validateField(name, value)
    })
  }

  handleEmailSubmit() {
    const { dispatch } = store
    const { emailValid, email, showEmailEdit } = this.state
    const { child } = this.props

    if (showEmailEdit) {
      if (emailValid) {
        activateServices
          .update({ email, portal_id: child.baseline_portal_id })
          .then(
            response => {
              dispatch(
                alertActions.success("Activation sent to " + email)
              )

              let records = JSON.parse(localStorage.getItem('records'))
              const index = records.findIndex(
                i => i.baseline_portal_id === child.baseline_portal_id
              )
              if (index >= 0) {
                records[index].email = email
                dispatch(recordsActions.refresh(records))
              }
            },
            error => {
              if (error.response) {
                const errors = error.response.data.errors
                if (errors) {
                  errors.forEach(e => {
                    dispatch(alertActions.error(e))
                  })
                }
              }
            }
          )
      }
    }
  }

  handleNameSubmit() {
    const { dispatch } = store
    const { first_name, showNameEdit } = this.state
    const { child } = this.props

    if (showNameEdit) {
      let records = JSON.parse(localStorage.getItem('records'))
      const index = records.findIndex(
        i => i.baseline_portal_id === child.baseline_portal_id
      )
      if (index >= 0) {
        records[index].first_name = first_name
        localStorage.setItem('records', JSON.stringify(records))
      }

      dispatch(recordsActions.update({first_name, portal_id: child.baseline_portal_id}))
      this.toggleNameEdit()
    }
  }

  toggleNameEdit() {
    const { showNameEdit } = this.state

    this.setState({
      showNameEdit: !showNameEdit,
    })
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors
    let { emailValid, email } = this.state

    switch (fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        fieldValidationErrors.email = emailValid ? '' : "Please enter a valid email address."
        break
      default:
        break
    }

    this.setState({
      formErrors: fieldValidationErrors,
      emailValid: emailValid,
      email: email,
    })
  }

  errorClass(error) {
    return error.length === 0 ? '' : 'has-error'
  }

  onDeleteConfirm = () => {
    this.setState({ deleteConfirmModal: false })
    this.props.onDelete()
  }

  onOptOutConfirm = () => {
    this.setState({ optoutConfirmModal: false })
    this.props.onOptOut()
  }

  render() {
    const {
      first_name,
      email,
      showEmailEdit,
      showNameEdit,
      formErrors,
      deleteConfirmModal,
      optoutConfirmModal,
    } = this.state
    const {
      child,
      tabName,
      showModal,
      onClose,
    } = this.props

    return (
      <div>
        <ReactModal
          style={{
            overlay: { backgroundColor: 'rgba(33, 33, 33, 0.85)' },
            content: {
              maxWidth: 1280,
              maxHeight: 650,
              width: '80%',
              margin: 'auto',
            },
          }}
          isOpen={showModal}
          shouldCloseOnEsc={true}
          shouldReturnFocusAfterClose={true}
          closeTimeoutMS={200}
          onRequestClose={onClose}
        >
          <XCircle
            size={32}
            onClick={onClose}
            style={{ cursor: 'pointer', float: 'right' }}
          />
          <div className="container mt-5">
            {showNameEdit ? (
              <div className="form-group row mb-4">
                <div className="form-group">
                  <input
                    type="text"
                    className="h1"
                    id="first_name"
                    name="first_name"
                    value={first_name}
                    ref={input => {
                      this.nameInput = input
                    }}
                    onChange={this.handleChange}
                  />
                </div>
                <div
                  className="form-group col-lg-1"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={this.handleNameSubmit}
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : (
              <div
                className="row mb-5"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <h1>{first_name ? first_name : tabName}</h1>
                <span
                  className="btn-custom-muted ml-4"
                  onClick={this.toggleNameEdit}
                >
                  <Edit2 size={16} className="mr-2" />
                  Edit Name
                </span>
              </div>
            )}
            <div>
              {showEmailEdit && (
                <div>
                  <div className="mt-2 mb-2">
                    <FormErrors formErrors={formErrors} />
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-1 col-form-label">
                      Email
                    </label>
                    <div className="col-lg-5">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={email}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div
                      className="form-group"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={this.handleEmailSubmit}
                        disabled={formErrors.email}
                      >
                        Resend activation email
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <ChildInfo child={child} />
              <div className="row pt-5">
                <div className="col-lg-4">
                  <h5>
                    Delete
                  </h5>
                  <p>
                    Delete{' '}{tabName}'s account but you can keep the account's data for the purpose of this study.
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-4 mb-4">
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={this.toggleDeleteModal}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
        <ConfirmationModal
          showModal={deleteConfirmModal}
          onClose={this.toggleDeleteModal}
          handleAction={this.onDeleteConfirm}
          action="delete"
          message="Deleting your child's account is irreversible. All content will be removed in the process, and cannot be restored"
        />
        <ConfirmationModal
          showModal={optoutConfirmModal}
          onClose={this.toggleOptoutModal}
          handleAction={this.onOptOutConfirm}
          action="opt out"
          message="Opting out your child's account is irreversible. All content will be removed in the process, and cannot be restored"
        />
      </div>
    )
  }
}

export default EditChildModal
