import React from 'react'

import { Section } from '../Profile/SectionHeading/Section'
import {WeeklySubmissionSummary} from "./WeeklySubmissionSummary";
import SurveyList from './SurveyList'

export const Surveys = props => {
  const {surveys, surveyType, record, surveyPath, handleInjuryChange, currentSports, role} = props

  if (surveyType === 'Weekly') {
    console.log(surveys)
  }

  return (
    <div>
      <Section heading={surveyType} role={role} />
        {surveyType === 'Weekly' && <WeeklySubmissionSummary record={record}/>}
      <SurveyList
        surveys={surveys}
        surveyType={surveyType}
        record={record}
        surveyPath={surveyPath}
        handleInjuryChange={handleInjuryChange}
        currentSports={currentSports}
      />
    </div>
  )
}
