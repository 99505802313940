import React from 'react'
import {Link} from 'react-router-dom'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {Mail} from 'react-feather'
import {Phone} from 'react-feather'


import siprc from '../../images/Affiliates/siprc_white.png'
import shred from '../../images/ShredLogos/secondary_shred.png'
import './Footer.css'

export const Footer = () => {
    const settings = {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 10000,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 770,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    }

    const currentYear = new Date().getFullYear()

    return (
        <footer className="footer">
            <div className="footer-secondary">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12" style={{cursor: 'grab'}}>
                            <Slider {...settings}>
                                <div>
                                    <img
                                        className="item affiliates"
                                        alt="siprc logo"
                                        src={siprc}
                                        height={100}
                                        width="auto"
                                        style={{margin: 'auto'}}
                                    />
                                </div>
                                <div>
                                    <img
                                        className="item affiliates"
                                        alt="nfl logo"
                                        src={shred}
                                        height={100}
                                        width="auto"
                                        style={{margin: 'auto'}}
                                    />
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-primary footer-background-color">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-3 footer-widget">
                            <h5 className="heading text-white">
                                Quick
                                <span className="text-primary">
                  {" Links"}
                </span>
                            </h5>
                            <ul className="quick-links">
                                <li>
                                    <Link to="/about" className="btn-custom-muted">
                                        About
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact-us" className="btn-custom-muted">
                                        Contact Us
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 footer-widget">
                            <h5 className="heading text-white">
                                Contact
                                <span className="text-primary">
                  {' '}Us
                </span>
                            </h5>
                            <address>
                                <p className="text-primary">
                                    University of Calgary
                                </p>
                                <span>
                  KNB 246
                  <br/>
                  Faculty of Kinesiology
                  <br/>
                  Univ. of Calgary
                  <br/>
                  <br/>
                  2500 University Dr. NW
                  <br/>
                  Calgary, AB
                  <br/>
                  T2N 1N4
                </span>
                                <br/>
                                <br/>
                            </address>
                            <div
                                style={{display: 'flex', alignItems: 'center'}}
                                className="text-primary"
                            >
                                <Mail size={16} className="mr-2"/>
                                danceinjury@ucalgary.ca
                            </div>
                            <div
                                style={{display: 'flex', alignItems: 'center'}}
                                className="text-primary"
                            >
                                <Phone size={16} className="mr-2"/>
                                403-220-2770
                            </div>
                        </div>
                        <div className="col-lg-3 footer-widget">
                            <h5 className="heading text-white">
                                &nbsp;
                            </h5>
                            <address>
                                <p className="text-primary">
                                    Wake Forest University
                                </p>
                                <span>
                  Department of Orthopaedic Surgery
                  <br/>
                  Wake Forest University
                  <br/>
                  <br/>
                  Medical Center Boulevard
                  <br/>
                  Winston-Salem
                  <br/>
                  NC 27157
                </span>
                                <br/>
                                <br/>
                            </address>
                            <div
                                style={{display: 'flex', alignItems: 'center'}}
                                className="text-primary"
                            >
                                <Mail size={16} className="mr-2"/>
                                gbullock@wakehealth.edu
                            </div>
                            <div
                                style={{display: 'flex', alignItems: 'center'}}
                                className="text-primary"
                            >
                                <Phone size={16} className="mr-2"/>
                                336-716-1787
                            </div>
                        </div>
                        <div className="col-lg-3 footer-widget">
                            <h5 className="heading text-white">
                                &nbsp;
                            </h5>
                            <address>
                                <p className="text-primary">
                                    La Trobe University
                                </p>
                                <span>
                  La Trobe Sport and Exercise Medicine Research Centre
                  <br/>
                  <br/>
                  <br/>
                  Plenty Road
                  <br/>
                  Bundoora Vic 3086
                </span>
                                <br/>
                                <br/>
                                <br/>
                            </address>
                            <div
                                style={{display: 'flex', alignItems: 'center'}}
                                className="text-primary"
                            >
                                <Mail size={16} className="mr-2"/>
                                e.rio@latrobe.edu.au
                            </div>
                            <div
                                style={{display: 'flex', alignItems: 'center'}}
                                className="text-primary"
                            >
                            </div>
                        </div>
                        <span className="copyright">
              &copy; Copyright SHRED {currentYear}
            </span>
                    </div>
                </div>
            </div>
        </footer>
    )
}
