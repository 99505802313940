import React from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import ReactModal from 'react-modal'
import {ClipLoader} from 'react-spinners'

import {store} from '../../../helpers/index'
import {FormErrors} from '../../FormErrors'
import {alertActions, request, success, failure} from '../../../actions/index'
import {siteServices} from '../../../services'
import {recordServices} from '../../../services'
import {authSuccess} from '../../../actions/index'
import PhoneInput from "react-phone-number-input";

class AddChild extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            first_name: '',
            last_name: '',
            date_of_birth: '',
            school_name: '',
            phone_number: '',
            formErrors: {
                school_name: '',
                email: '',
                first_name: '',
                last_name: '',
                phone_number: '',
            },
            dobValid: false,
            siteValid: false,
            emailValid: false,
            formValid: false,
            firstNameValid: false,
            lastNameValid: false,
            phoneNumberValid: false,
            sites: {'schools': []},
            childAddedModalOpen: false,
            loadingChild: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleDateChange = this.handleDateChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        const {dispatch} = store

        siteServices.get().then(
            response => {
                const data = response.data

                this.setState({
                    sites: data,
                })
            },
            error => {
                if (error.response) {
                    const errors = error.response.data.errors
                    if (errors) {
                        errors.forEach(e => {
                            dispatch(alertActions.error(e))
                        })
                    }
                }
            }
        )
    }

    handleChange(event, type = '') {
        let name = ''
        let value = ''
        if (type === 'phone_number') {
            name = 'phone_number'
            value = event
        } else {
            name = event.target.name
            value = event.target.value
        }

        this.setState({[name]: value}, () => {
            this.validateField(name, value)
        })
    }

    handleDateChange(date) {
        if (date) {
            this.setState({
                date_of_birth: date,
                dobValid: true,
            })

            this.validateForm()
        }
    }

    handleDateChangeRaw = e => {
        e.preventDefault()
    }

    handleSubmit(event) {
        event.preventDefault()
        const {dispatch} = store
        const {
            formValid,
            email,
            first_name,
            last_name,
            date_of_birth,
            school_name,
            phone_number,
        } = this.state

        if (formValid) {
            this.setState({loadingChild: true})

            recordServices
                .create({
                    email,
                    first_name,
                    last_name,
                    date_of_birth,
                    school_name,
                    phone_number,
                })
                .then(
                    response => {
                        if ('token' in response.data) {
                            const token = response.data.token
                            localStorage.setItem('token', token)
                            dispatch(authSuccess(token))
                        }
                        this.setState({childAddedModalOpen: true})
                    },
                    error => {
                        this.setState({loadingChild: false})
                        if (error.response) {
                            const errors = error.response.data.errors
                            if (errors) {
                                errors.forEach(e => {
                                    dispatch(alertActions.error(e))
                                })
                            }
                        }
                    }
                )
        }

        this.setState({
            email: '',
            first_name: '',
            last_name: '',
            date_of_birth: '',
            school_name: '',
            phone_number: '',
            formErrors: {school_name: '', email: '', first_name: '', last_name: ''},
            dobValid: false,
            siteValid: false,
            emailValid: false,
            firstNameValid: false,
            lastNameValid: false,
            formValid: false,
        })
    }

    populateSites(type) {
        const {sites} = this.state

        let items = []
        if (sites[type] && sites[type].length) {
            for (let i = 0; i < sites[type].length; i++)
                items.push(<option key={i}>{sites[type][i]}</option>)
        }

        return items
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors
        let {
            dobValid,
            siteValid,
            emailValid,
            firstNameValid,
            lastNameValid,
            phoneNumberValid,
            school_name,
        } = this.state

        switch (fieldName) {
            case 'email':
                emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
                fieldValidationErrors.email = emailValid ? '' : "Please enter a valid email address"
                break
            case 'first_name':
                firstNameValid = value.length && typeof value === 'string'
                fieldValidationErrors.first_name = firstNameValid ? '' : "Please enter a valid first name"
                break
            case 'last_name':
                lastNameValid = value.length && typeof value === 'string'
                fieldValidationErrors.last_name = lastNameValid ? '' : "Please enter a valid last name"
                break
            case 'school_name':
                siteValid = (school_name !== 'Select school...' && school_name !== '')
                fieldValidationErrors.school_name = siteValid ? '' : "Please select a school/studio from the dropdown list"
                break
            case 'phone_number':
                phoneNumberValid = value !== ''
                break
            default:
                break
        }

        this.setState(
            {
                formErrors: fieldValidationErrors,
                dobValid: dobValid,
                siteValid: siteValid,
                emailValid: emailValid,
                firstNameValid: firstNameValid,
                lastNameValid: lastNameValid,
                phoneNumberValid: phoneNumberValid,
            },
            this.validateForm
        )
    }

    validateForm() {
        const {
            dobValid,
            siteValid,
            emailValid,
            firstNameValid,
            lastNameValid,
            phoneNumberValid,
        } = this.state

        this.setState({
            formValid:
                dobValid &&
                emailValid &&
                siteValid &&
                firstNameValid &&
                lastNameValid &&
                phoneNumberValid,
        })
    }

    errorClass(error) {
        return error.length === 0 ? '' : 'has-error'
    }

    closeModal = () => {
        const {dispatch} = store
        this.setState({childAddedModalOpen: false})

        dispatch(request())
        recordServices.get().then(
            response => {
                this.setState({loadingChild: false})
                const records = response.data.data
                if (records) {
                    localStorage.setItem('records', JSON.stringify(records))
                    dispatch(success(records))
                }
            },
            error => {
                this.setState({loadingChild: false})
                if (error.response) {
                    const errors = error.response.data.errors
                    if (errors) {
                        dispatch(failure(errors))
                        errors.forEach(e => {
                            dispatch(alertActions.error(e))
                        })
                    }
                }
            }
        )
        dispatch(alertActions.success("Child was added successfully, it may take a moment before they appear on your dashboard"))
    }

    render() {
        const {
            email,
            first_name,
            last_name,
            date_of_birth,
            school_name,
            phone_number,
            childAddedModalOpen,
            loadingChild,
            formErrors,
            formValid,
            sites
        } = this.state
        return (
            <div className="mt-5">
                <ReactModal
                    style={{
                        overlay: {backgroundColor: 'rgba(33, 33, 33, 0.85)'},
                        content: {
                            maxWidth: 650,
                            maxHeight: 340,
                            width: '80%',
                            margin: 'auto',
                        },
                    }}
                    isOpen={childAddedModalOpen}
                    onRequestClose={this.closeModal}
                >
                    <div style={{textAlign: 'center', paddingTop: '0.5rem'}}>
                        <p>
                            <b>Thank you for adding your child</b>
                        </p>
                        <p>
                            Your child should receive an account activation email from <br/>
                            <a href="!#"> no-reply@shredinjuries.ca</a> (please check the
                            junk or spam folder) If they do not receive this email you can
                            resend it on the edit child page.
                        </p>
                        <p>
                            <b>
                                Please complete the baseline surveys for your child by selecting
                                their name above
                            </b>
                        </p>
                    </div>
                    <div style={{textAlign: 'center', paddingTop: '2rem'}}>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.closeModal()
                            }}
                            style={{width: '75px'}}
                        >
                            Close
                        </button>
                    </div>
                </ReactModal>
                {loadingChild ? (
                    <ClipLoader
                        loading={true}
                        color={'#fff'}
                        css={{display: 'block', margin: '0 auto', borderColor: 'red'}}
                        size={45}
                    />
                ) : (
                    <React.Fragment>
                        <h2 className="mb-4">
                            Add
                            <span className="text-primary">
                {' '}Child
              </span>
                        </h2>
                        <div className="mt-2 mb-2">
                            <FormErrors formErrors={formErrors}/>
                        </div>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group row">
                                <label className="col-lg-2 col-form-label">
                                    Date Of Birth
                                </label>
                                <div className="col-lg-3 mb-2">
                                    <DatePicker
                                        id="date_of_birth"
                                        className="form-control"
                                        name="date_of_birth"
                                        placeholderText="mm/dd/yyyy"
                                        maxDate={new Date(2012, 11, 31)}
                                        minDate={new Date(1990, 1, 1)}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        selected={date_of_birth}
                                        onChange={this.handleDateChange}
                                        onChangeRaw={this.handleDateChangeRaw}
                                        autoComplete="off"
                                    ></DatePicker>
                                </div>
                                <label className="col-lg-2 col-form-label">
                                    Age:{' '}
                                    {date_of_birth ? (
                                        <b>{moment().diff(date_of_birth, 'years')}</b>
                                    ) : (
                                        ''
                                    )}
                                </label>
                            </div>
                            <div className="form-group row">
                                <label className="col-lg-2 col-form-label">
                                    First Name
                                </label>
                                <div className="col-lg-6 mb-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="first_name"
                                        name="first_name"
                                        value={first_name}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-lg-2 col-form-label">
                                    Last Name
                                </label>
                                <div className="col-lg-6 mb-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="last_name"
                                        name="last_name"
                                        value={last_name}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-lg-2 col-form-label">
                                    Child's Email
                                </label>
                                <div className="col-lg-6 mb-2">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        placeholder="example@gmail.com"
                                        name="email"
                                        value={email}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-lg-2 col-form-label">
                                    Child's OR Parent's Phone Number
                                </label>
                                <div className="col-lg-6 mb-2">
                                    <PhoneInput
                                        className='form-control'
                                        countries={['CA', 'US', 'AU']}
                                        name='phone_number'
                                        defaultCountry='CA'
                                        value={phone_number}
                                        onChange={value => this.handleChange(value, 'phone_number')}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-lg-2 col-form-label">
                                    School/Dance Studio
                                </label>
                                <div className="col-lg-6 mb-2">
                                    <select
                                        id="school_name"
                                        className="form-control"
                                        name="school_name"
                                        value={school_name}
                                        onChange={this.handleChange}
                                    >
                                        <option defaultValue={true}>
                                            Select school/studio...
                                        </option>
                                        {this.populateSites('schools')}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <button
                                    type="submit"
                                    className="btn btn-primary mt-4"
                                    disabled={!formValid}
                                >
                                    Add Child
                                </button>
                                <button
                                    className="btn btn-primary mt-4 ml-2"
                                    onClick={this.props.handleCancel}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </React.Fragment>
                )}
            </div>
        )
    }
}

export default AddChild
