import React from 'react'

import { store } from '../../helpers/index'
import { confirmationServices } from '../../services'
import { alertActions } from '../../actions'

class ConfirmationNeeded extends React.Component {
  constructor(props) {
    super(props)

    this.handleResendConfirmation = this.handleResendConfirmation.bind(this)
  }

  handleResendConfirmation(event) {
    const { dispatch } = store
    const token = localStorage.getItem('token')

    confirmationServices.update(token).then(
      response => {
        dispatch(alertActions.success('Email sent'))
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors
          if (errors) {
            errors.forEach(e => {
              dispatch(alertActions.error(e))
            })
          }
        }
      }
    )
  }

  render() {

    return (
      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="heading">
              Account Confirmation Needed
            </h2>
            <div>
              <p className="lead">
                Your account has not been confirmed yet
              </p>
              <button
                className="btn btn-primary"
                onClick={this.handleResendConfirmation}
              >
                Resend confirmation email
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ConfirmationNeeded
