import React, {useState} from 'react'
import {Collapse} from "reactstrap";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";

export const WeeklySubmissionSummary = ({record}) => {
    const [summaryOpen, setSummaryOpen] = useState(false)

    return (
        <div className={"row"}>
            <div className='col-lg-10'>
                <div className="card" style={{width: '100%', borderRadius: 0, cursor: 'pointer'}}
                     onClick={() => setSummaryOpen(!summaryOpen)}>
                    <div className="card-body" style={{padding: '0.6rem 0 0.6rem 3rem'}}>
                        <h5 className="text-primary">
                            <a
                                style={{textDecoration: 'none'}}
                                href="#!"
                            >
                                {summaryOpen ? 'Close' : 'View'} previous weekly submission dates
                                <span className="text-right">
                        {summaryOpen ? (
                            <IoIosArrowUp style={{verticalAlign: 'middle'}}/>
                        ) : (
                            <IoIosArrowDown style={{verticalAlign: 'middle'}}/>
                        )}
                      </span>
                            </a>
                        </h5>
                        <Collapse isOpen={summaryOpen}>
                            <div>
                                <b>Dance Health Problems Questionnaire</b>
                                <ul>
                                    {record?.weekly_submissions?.health_problems_submissions?.length > 0 ? record?.weekly_submissions?.health_problems_submissions.map(date =>
                                        <li>{date}</li>) : <li>None</li>}
                                </ul>
                            </div>
                            <div>
                                <b>Dance Exposure Questionnaire</b>
                                <ul>
                                    {record?.weekly_submissions?.exposure_submissions?.length > 0 ? record?.weekly_submissions?.exposure_submissions.map(date =>
                                        <li>{date}</li>) : <li>None</li>}
                                </ul>
                            </div>
                            
                        </Collapse>
                    </div>
                </div>
            </div>
        </div>
    )
}