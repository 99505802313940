import jwtDecode from 'jwt-decode'

import { sessionConstants } from '../constants'

let token = localStorage.getItem('token')
let email = ''
let state = ''
let role = ''
let firstName = ''

if (token) {
  email = jwtDecode(token).email
  state = jwtDecode(token).state
  role = jwtDecode(token).role
  firstName = jwtDecode(token).first_name
}

const initialState = email
  ? {
      token: token,
      username: email,
      state: state,
      role: role,
      firstName: firstName,
      loading: false,
      isAuthenticated: true,
    }
  : {
      loading: false,
    }

export function session(state = initialState, action) {
  switch (action.type) {
    case sessionConstants.LOGIN_REQUEST:
      return {
        loading: true,
        error: false,
        errors: [],
      }
    case sessionConstants.LOGIN_SUCCESS:
    case sessionConstants.SESSION_UPDATE_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        state: jwtDecode(action.token).state,
        role: jwtDecode(action.token).role,
        username: jwtDecode(action.token).email,
        firstName: jwtDecode(action.token).first_name,
        token: action.token,
        error: false,
        errors: [],
      }
    case sessionConstants.LOGIN_FAILURE:
      return {
        loading: false,
        isAuthenticated: false,
        token: null,
        username: null,
        error: true,
        errors: action.errors,
      }
    case sessionConstants.LOGOUT_REQUEST:
    case sessionConstants.LOGOUT_SUCCESS:
      return {
        loading: false,
      }
    default:
      return state
  }
}
