import React from 'react'
import header from '../../images/Consent/Calgary/logo.png'

export class AlbertaBalletConsent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      share_findings: '',
      keep_research_data: '',
      agree_to_be_contacted: '',
      parent_name: '',
      child_name: '',
      witness_name: '',
      person_obtaining_consent_name: '',
    }
  }

  validateComplete = () => {
    const {
      parent_name,
      child_name,
      witness_name,
      person_obtaining_consent_name,
      share_findings,
      keep_research_data,
      agree_to_be_contacted
    } = this.state

    let data = this.state
    data.formValid = true

    if (parent_name && child_name && witness_name && person_obtaining_consent_name && share_findings && keep_research_data && agree_to_be_contacted) {
      data.formValid = true
    } else {
      data.formValid = false
    }

    this.props.handleChange(data)
  }

  handleChange = event => {
    const {name, value} = event.target
    if (['parent_name', 'child_name', 'witness_name', 'person_obtaining_consent_name', 'share_findings', 'keep_research_data', 'agree_to_be_contacted'].includes(name)) {
      this.setState(
        {
          [name]: value,
        },
        this.validateComplete
      )
    }
  }

  render() {
    const {parent_name, child_name, witness_name, person_obtaining_consent_name} = this.state

    return (
      <div>
        <img
          src={header}
          alt="header"
          style={{
            width: '150px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
        <br/>
        <br/>
        <br/>
        <div style={{textAlign: 'center', width: '100%'}}>
          <h5>UNIVERSITY OF CALGARY</h5>
          <h5>PARENT/GUARDIAN CONSENT FOR CHILD TO PARTICIPATE IN RESEARCH</h5>
        </div>
        <br/>
        <p>
          <b>Title: </b>
          <br/>Burden of health problems in dancers
        </p>
        <p>
          <b>Sponsor: </b>
          <br/>Sport Injury Prevention Research Centre, University of Calgary
        </p>
        <p>
          <b>Investigators: </b>
          <br/>
          <div style={{display: 'flex'}}>
            <div style={{width: '200px'}}>Principal Investigator:</div>
            <div style={{width: '200px'}}>Dr. Sarah Kenny <br/></div>
          </div>
          <div style={{display: 'flex'}}>
            <div style={{width: '200px'}}>Co-Investigators:</div>
            <div style={{width: '400px'}}>Dr. Meghan Critchley & Dr. Carolyn Emery<br/></div>
          </div>
        </p>
        <p>
          <b>Contact Information: </b>
          <br/>
          <div style={{display: 'flex'}}>
            <div style={{width: '200px'}}>Email:</div>
            <div style={{width: '200px'}}><i>danceinjury@ucalgary.ca</i><br/></div>
          </div>
        </p>
        <p>
          <b>Introduction</b>
          <br/>
          Dr. Sarah Kenny and colleagues from the Faculty of Kinesiology at the University of Calgary are conducting a
          research study. This consent form is only part of the process of informed consent. It should give you the
          basic idea of what the research is about, and what your child’s participation will involve. If you would like
          more detail about something mentioned here, or information not included here, please ask. Take the time to
          read this carefully and to understand any accompanying information. Your child was identified as a possible
          participant in this study because they are a dancer in Calgary and area. Your child’s participation in this
          research study is voluntary.
        </p>
        <p>
          <b>Why is this study being done?</b>
          <br/>
          To master their craft, dancers participate in rigorous training that incorporates both physical and artistic
          requirements. Research suggests that dance is a high-risk activity with high prevalence of health problems
          (i.e., injuries and illnesses). Attempts to understand potential causes of health problems in dance have
          focused on a variety of factors (e.g., age, previous injury, intensity of training), but efforts are still
          needed to develop effective ways of reducing the risk of health problems in dancers. Put simply, a dance
          injury or illness will keep your child from dancing and being active, which can change how healthy they are.
          Research is important to help understand how to keep these dance health problems from happening. It helps us
          understand how many injuries and illnesses there are and what increases the chances of getting hurt or sick.
          This research will also help us come up with ways to reduce injuries and illnesses from happening in the
          future. The purpose of this research study is to understand patterns of dance-related health problems and if
          there are certain measures that can help reduce the chances of health problems occurring in dancers.
        </p>
        <p>
          <b>How how many people will take part in this study?</b>
          <br/>
          About 200 people will take part in this study from studios across Calgary and area.
        </p>
        <p>
          <b>What will happen if my child takes part in this research study?</b>
          <br/>
          If you agree to allow your child to participate in this study, we will ask them to:
        </p>
        <p>
          <b>Before training starts:</b>
          <br/>
          Your child will fill out forms online at home that include a medical questionnaire, a dance history form, and
          a psychological skills form. A few questions are sensitive in nature (e.g., questions concerning psychological
          stress, sleep habits, and menstrual health) that may be upsetting to some individuals. After completing the
          forms, they will participate in physical assessments that will measure how tall your child is, how much they
          weigh, how much range of motion is in their ankles and hips, the strength of their legs, how well they control
          their pelvis, and balance. A certified physiotherapist and trained research assistant will conduct all the
          assessments. It should take approximately one hour to complete all tests. Your child will be excused from
          class to participate. All assessments will take place at your child’s dance studio in front of others and be
          repeated at the end of the dance season.
        </p>
        <p>
          <b>During the school year:</b>
          <br/>
          After testing, your child will keep track of how many hours they dance each week (including classes,
          rehearsals, and performances). Your child will keep track if get injured or have an illness at any time during
          the school year by filling out a weekly questionnaire online. An injury means any physical or psychological
          complaint resulting from class, rehearsal, or performance regardless of its consequences (e.g., having to miss
          class or see a medical practitioner). A physiotherapist will support your child if they get injured, providing
          recommendations for any treatment and rehabilitation they may need. The cost of medical care will be at your
          own cost or health coverage.
        </p>
        <p>
          <b>How long will my child be in the research study?</b>
          <br/>
          Participation will take approximately one hour during the baseline and end of year testing. Weekly surveys
          will take approximately 5 minutes each week for the duration of the dance season. If a survey is missed,
          reminder emails will be sent every two days, for up to two occasions.
        </p>
        <p>
          <b>Are there any potential risks or discomforts that my child can expect from this study?</b>
          <br/>
          We will do everything we can to ensure that your child does not experience any discomfort during the physical
          assessments. It is possible that discomfort could arise during the tests, but this is unlikely, because they
          will be moving within their normal ranges of movement and muscle strength. If your child experiences
          discomfort, testing will cease immediately.
        </p>
        <p>
          <b>Are there any potential benefits to my child if they participate?</b>
          <br/>
          If you agree for your child to participate in this study, there may or may not be a direct benefit to them. If
          they have been identified as having a dance injury or illness, their condition may be improved during the
          study but there is no guarantee that this research will help them. The information we get from this study may
          help us to develop strategies to reduce the risk of dance-related injuries and illness in the future.
        </p>
        <p>
          <b>What other choices does my child have if they do not participate?</b>
          <br/>
          Your child does not have to be in the study and can leave the study at any time by telling a member of the
          research team, or Dr. Sarah Kenny (403-220-2770; danceinjury@ucalgary.ca). You can ask questions at any time
          during the study, and if anything new about the study comes up while it is happening, you will be told.
        </p>
        <p>
          <b>Can my child stop being in the study?</b>
          <br/>
          Yes. You can decide to stop your child’s participation at any time. Tell the researchers if you are thinking
          about stopping or decide to stop your child’s participation.
        </p>
        <p>
          <b>Can the researchers remove my child from this study?</b>
          <br/>
          The researchers may end your child’s participation in this study for several reasons, such as if their safety
          and welfare are at risk, or if they do not follow instructions. The researchers or the study sponsor might
          also decide to stop the study at any time.
        </p>
        <p>
          <b>What if researchers discover something about my child?</b>
          <br/>
          During the study, the researchers could learn something about your child that they didn’t expect. For example,
          the researchers may find out that your child has another medical condition. The researchers will consult with
          medical experts as needed to evaluate the findings and will then share these results with you. You will be
          helped with arranging appropriate follow up and care for your child.
          <br/>
          <br/>
          If any mental health concerns requiring care are uncovered, this information may be shared with you. You may
          be guided by a member of the research team to follow up with your family physician and provided a list of
          resources (e.g., local dance psychologists).
          <br/>
          <br/>
          <hr/>
          <div className="form-group row">
            <label className="col-lg-6 col-form-label">
              I consent for the researchers to share findings about my child with me
            </label>
            <div className="col-lg-4 mb-2">
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="share_findings"
                    id="share_findings_yes"
                    value={true}
                    onClick={this.handleChange}
                  />
                  Yes
                </label>
              </div>
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="share_findings"
                    id="share_findings_no"
                    value={false}
                    onClick={this.handleChange}
                  />
                  No
                </label>
              </div>
            </div>
          </div>
          <hr/>
        </p>
        <p>
          <b>Withdrawal of study data</b>
          <br/>
          You are free to withdraw your child from the study at any time. Should you wish to withdraw them from this
          study, you can also request that their data be removed from the study. You will be able to withdraw their data
          from this study until data analysis begins up to 2 months after then end of the school year. At this point,
          you will no longer be able to request to have their data removed from the study.
        </p>
        <p>
          <b>Will my child be paid for participating?</b>
          <br/>
          Your child or you will not get any money or gifts for being in this research study.
        </p>
        <p>
          <b>Do I have to pay for anything?</b>
          <br/>
          You will not have to pay for anything. There are no costs associated with this study as participation will
          take place at your child’s dance studio.
        </p>
        <p>
          <b>Will information about my child's participation be kept confidential?</b>
          <br/>
          The researchers will do their best to make sure that your child’s private information is kept confidential.
          Information about your child will be handled as confidentially as possible, but participating in research may
          involve a loss of privacy and the potential for a breach in confidentiality. The research team will handle
          data according to the Data Management Plan as outlined below:
          Some identifiable information about your child will be replaced with a code. A master list linking the code
          and their identifiable information will be kept separate from the research data. Only the investigators
          responsible for this study, the research team, the University of Calgary, and the Conjoint Health Research
          Ethics Board will have access to this information. Confidentiality will be protected by using only study
          identification numbers in the database. Any results of the study, which are reported, will in no way identify
          your child individual. The de-identified data may be combined with data from other studies conducted by the
          research team. This study utilizes an online survey company (REDCap), which stores identifying data
          separately, securely, and is a double authentication password-protected secure database. This data will be
          stored securely on servers within Canada.
        </p>
        <p>
          <b>How long will information from this study be kept?</b>
          <br/>
          The researchers intend to keep and store the research data and records for approximately 5 years on a secure
          server. If you consent to the use of your child’s research data for use in future research, it may be kept for
          a longer period. Any future use of this research data is required to undergo review by a Research Ethics
          Board.
        </p>
        <p>
          <b>Use of data for future research</b>
          <br/>
          <br/>
          <div className="form-group row">
            <label className="col-lg-6 col-form-label">
              My child’s research data may be kept for use in future research to learn about, prevent or treat other
              health-related problems.
            </label>
            <div className="col-lg-4 mb-2">
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="keep_research_data"
                    id="keep_research_data_yes"
                    value={true}
                    onClick={this.handleChange}
                  />
                  Yes
                </label>
              </div>
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="keep_research_data"
                    id="keep_research_data_no"
                    value={false}
                    onClick={this.handleChange}
                  />
                  No
                </label>
              </div>
            </div>
          </div>
          <hr/>
        </p>
        <p>
          <b>Contact for future research</b>
          <br/>
          <br/>
          <div className="form-group row">
            <label className="col-lg-6 col-form-label">
              University of Calgary researchers may contact me in the future to ask for my child to take part in other
              research studies.
            </label>
            <div className="col-lg-4 mb-2">
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="agree_to_be_contacted"
                    id="agree_to_be_contacted_yes"
                    value={true}
                    onClick={this.handleChange}
                  />
                  Yes
                </label>
              </div>
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="agree_to_be_contacted"
                    id="agree_to_be_contacted_no"
                    value={false}
                    onClick={this.handleChange}
                  />
                  No
                </label>
              </div>
            </div>
          </div>
          <hr/>
        </p>
        <p>
          <b>If my child suffers a research-related injury, will we be compensated?</b>
          <br/>
          It is important that you tell the researchers if you believe that your child has been injured because of
          taking part in this study. If your child suffers an injury because of participating in this research, no
          compensation will be provided to you or your child by the Sport Injury Prevention Research Centre, the
          University of Calgary, Alberta Health Services, or the researchers. However, you still have all your legal
          rights. Nothing said in this consent form alters your right, or your child’s right, to seek damages.
        </p>
        <p>
          <b>Who can I contact if I have questions about this study?</b>
          <br/>
          <br/>
          <b style={{fontSize: '15px'}}>The Research Team:</b>
          <br/>
          You may contact Dr. Sarah Kenny at 403-220-2770 or danceinjury@ucalgary.ca with any questions or concerns
          about the research or your child’s participation in this study.
          <br/>
          <br/>
          <b>Conjoint Health Research Ethics Board (CHREB):</b>
          <br/>
          If you have any questions concerning your child’s rights as a possible participant in this research, please
          contact the Chair, Conjoint Health Research Ethics Board, University of Calgary at 403-220-7990.
        </p>
        <p>
          <b>What are my child's rights if they take part in this study?</b>
          <br/>
          Your child’s participation in this study is a choice. You can choose whether or not you want your child to
          participate. Whatever decision you make, there will be no penalty to you or your child.
          <ul>
            <li>
              You have a right for all your questions to be answered before deciding if your child will take part.
            </li>
            <li>
              Your decision will not affect the standard medical care or education your child receives.
            </li>
            <li>
              If you decide for your child to take part, they can leave the study at any time.
            </li>
            <li>
              Your child may refuse to answer any questions that they do not want to answer and remain in the study.
            </li>
          </ul>
        </p>
        <p>
          <b>How do I indicate agreement for my child to participate?</b>
          <br/>
          Your signature on this form indicates that you have understood to your satisfaction the information regarding
          your child’s participation in the research project and agree for them to participate as a participant. In no
          way does this waive your or your child’s legal rights nor release the investigators or involved institutions
          from their legal and professional responsibilities.
        </p>
        <p>
          <b></b>
          <br/>
        </p>

        <hr/>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Name of child:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="child_name"
              name="child_name"
              value={child_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Name of parent or legal guardian:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="parent_name"
              name="parent_name"
              value={parent_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Name of person obtaining consent:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="person_obtaining_consent_name"
              name="person_obtaining_consent_name"
              value={person_obtaining_consent_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label">
            Name of witness:
          </label>
          <div className={'col-lg-4 mb-2'}>
            <input
              type="text"
              className="form-control"
              id="witness_name"
              name="witness_name"
              value={witness_name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <hr/>
      </div>
    )
  }
}
