import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Redirect } from 'react-router-dom'

import { default as ConfirmationNeeded } from '../../components/Account/ConfirmationNeeded'
import { sessionActions } from '../../actions'
import { store } from '../../helpers/index'

export function requireAuthentication(Component) {
  class RequireAuthentication extends React.Component {
    componentDidMount() {
      this.checkAuth(this.props)
    }

    componentWillReceiveProps(nextProps) {
      this.checkAuth(nextProps)
    }

    checkAuth(props) {
      const { dispatch } = store

      if (!props.isAuthenticated) {
        const token = localStorage.getItem('token')
        if (token) dispatch(sessionActions.destroy())

        dispatch(push('/'))
      } else {
        const token = localStorage.getItem('token')
        if (!token) {
          if (props.storeToken)
            localStorage.setItem('token', props.storeToken)
          else {
            dispatch(sessionActions.destroy())
            dispatch(push('/'))
          }
        }
      }
    }

    render() {
      return (
        <div>
          {this.props.isAuthenticated && this.props.state === 'active' ? (
            <Component {...this.props} />
          ) : this.props.state === 'pending' ? (
            <ConfirmationNeeded />
          ) : (
            <Redirect to="/" />
          )}
        </div>
      )
    }
  }

  function mapStateToProps(state) {
    return {
      isAuthenticated: state.session.isAuthenticated,
      state: state.session.state,
      storeToken: state.session.token,
    }
  }
  return connect(mapStateToProps)(RequireAuthentication)
}
