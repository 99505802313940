import {AlbertaBalletAssent} from "./AlbertaBalletAssent"
import {AlbertaBalletConsent} from "./AlbertaBalletConsent"
import {CalgaryYouthConsent} from './CalgaryYouthConsent'
import {CalgaryYouthAssent} from './CalgaryYouthAssent'
import {LaTrobeConsent} from "./LaTrobeConsent"
import {LaTrobeAssent} from "./LaTrobeAssent"
import {WakeForestConsent} from "./WakeForestConsent"
import {WakeForestAssent} from "./WakeForestAssent"

export const consentForms = {
    "University_of_Calgary_-_Alberta_Ballet_School": AlbertaBalletConsent,
    "University_of_Calgary_-_Youth_Dance_Injury_Study": CalgaryYouthConsent,
    "Wake_Forest_University": WakeForestConsent,
    "La_Trobe_University": LaTrobeConsent
}

export const assentForms = {
    "University_of_Calgary_-_Alberta_Ballet_School": AlbertaBalletAssent,
    "University_of_Calgary_-_Youth_Dance_Injury_Study": CalgaryYouthAssent,
    "Wake_Forest_University": WakeForestAssent,
    "La_Trobe_University": LaTrobeAssent
}

export const consentContentRequired = {
    "University_of_Calgary_-_Alberta_Ballet_School": true,
    "University_of_Calgary_-_Youth_Dance_Injury_Study": true,
    "Wake_Forest_University": false
}

export const assentContentRequired = {
    "University_of_Calgary_-_Youth_Dance_Injury_Study": true,
    "Wake_Forest_University": false
}
