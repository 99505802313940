import React from 'react'

import moment from 'moment'


const concussionTrack = {
  "active": "Active",
  "inactive": "Inactive",
  "return_play": "Return to Play",
  "finished": "Finished"
}

class ChildInfo extends React.Component {
  render() {
    const { child } = this.props

    return (
      <div>
        <div className="row">
          <div className="col-lg-4">
            <div className="row">
              <label className="col-12 col-form-label">
                Concussion Status
              </label>
              <span className="col-lg-12 mt-1 muted-color">
                {child && child.concussion_track ? concussionTrack[child.concussion_track] : '-'}
              </span>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="row">
              <label className="col-12 col-form-label">
                Concussion Date
              </label>
              <span className="col-lg-12 mt-1 muted-color">
                {child && child.concussion_date ? moment.utc(child.concussion_date).format('LL') : '-'}
              </span>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="row">
              <label className="col-12 col-form-label">
                Date of Birth
              </label>
              <span className="col-lg-12 mt-1 muted-color">
                {child && child.date_of_birth ? moment.utc(child.date_of_birth).format('LL') : '-'}
              </span>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-6">
            <div className="row">
              <label className="col-12 col-form-label">
                School
              </label>
              <span className="col-lg-12 mt-1 muted-color">
                {child ? child.site : '-'}
              </span>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <label className="col-12 col-form-label">
                Email
              </label>
              <span className="col-lg-12 mt-1 muted-color">
                {child ? child.email : '-'}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ChildInfo
