import React from 'react'

import { Mail } from 'react-feather'
import { Phone } from 'react-feather'


export const ContactUs = () => (
    <div className="container mt-4 mb-4">
      <div className="row">
        <div className="col-lg-12">
          <h2 className="heading mb-5">
            contact{' '}
            <span className="text-primary">
            us
          </span>
          </h2>
        </div>
      </div>
      <div className="col-lg-12">
        <h5 className="mb-4">
          University of Calgary:
        </h5>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <address className="bg-light p-3">
            Study Coordinator: Dr. Meghan Critchley
            <br/>
            Faculty of Kinesiology,
            <br/>
            University of Calgary
            <br/>
            2500 University Dr. NW
            <br/>
            Calgary, AB T2N 1N4
            <br/>
            <br/>
            <br/>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Mail className="mr-2"/>
              danceinjury@ucalgary.ca
              <br/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Phone className="mr-2"/>
              403-220-2770
            </div>
          </address>
        </div>
        <div className="col-lg-6">
          <address className="bg-light p-3">
            Principal Investigator: Dr. Sarah Kenny
            <br/>
            Faculty of Kinesiology,
            <br/>
            University of Calgary
            <br/>
            2500 University Dr. NW
            <br/>
            Calgary, AB T2N 1N4
            <br/>
            <br/>
            <br/>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Mail className="mr-2"/>
              kennys@ucalgary.ca
              <br/>
            </div>
          </address>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <h5 className="mb-4">
            <hr/>
          </h5>
        </div>
      </div>
      <div className="col-lg-12">
        <h5 className="mb-4">
          Wake Forest:
        </h5>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <address className="bg-light p-3">
            Study Coordinator: Dr. Garrett S.
            <br/>
            Department of Orthopaedic Surgery,
            <br/>
            Wake Forest University
            <br/>
            Medical Center Blvd
            <br/>
            Winston-Salem, NC 27151
            <br/>
            <br/>
            <br/>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Mail className="mr-2"/>
              gbullock@wakehealth.edu
              <br/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Phone className="mr-2"/>
              363-716-1787
            </div>
          </address>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <h5 className="mb-4">
            <hr/>
          </h5>
        </div>
      </div>
      <div className="col-lg-12">
        <h5 className="mb-4">
          La Trobe University:
        </h5>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <address className="bg-light p-3">
            La Trobe Sport and Exercise Medicine Research Centre
            <br/>
            Plenty Road
            <br/>
            Bundoora Vic 3086
            <br/>
            <br/>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Mail className="mr-2"/>
              <i style={{marginRight: '0.75rem'}}>Ebonie Rio:</i>e.rio@latrobe.edu.au
              <br/>
            </div>
          </address>
        </div>
      </div>
    </div>
)
