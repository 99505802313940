import React from 'react'

export class WakeForestAssent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      consentConfirmation: ''
    }
  }

  validateComplete = () => {
    const {consentConfirmation} = this.state

    let data = this.state
    data.formValid = true

    if (consentConfirmation) {
      data.formValid = true
    } else {
      data.formValid = false
    }

    this.props.handleChange(data)
  }

  handleChange = event => {
    const {name, value} = event.target
    if (['consentConfirmation'].includes(name)) {
      this.setState(
        {
          [name]: value,
        },
        this.validateComplete
      )
    }
  }

  render() {
    return (
      <div>
        <b>I confirm that I have completed a consent/assent form with study staff</b>
        <br/>
        <br/>
        <i>*If you have not yet completed paper consent, please contact study staff at <b>gbullock@wakehealth.edu</b></i>
        <hr/>
      </div>
    )
  }
}
